<template>
<div style="text-align: left">
  <Card style="height:100%">
    <div class="search-con search-con-top">
      <Input clearable placeholder="输入通知名 搜索" class="search-input" v-model="queryinfo.name"
             @on-enter="namechange"
             @on-change="namechange"/>
      <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
      <slot name="new_btn">
        <Button type="primary" class="search-btn"  @click="showadd">新建</Button>
      </slot>
    </div>
    <div>
      <Table border ref="selection" :columns="columns" :data="data" ></Table>
    </div>
    <br />
    <template>
      <Page :current="queryinfo.page"
            :total="total"
            :page-size-opts="pagesizeopts"
            @on-change="changepage"
            @on-page-size-change="changepagesize"
            show-total
            show-sizer
            style="text-align: right;" />
    </template>
    <Drawer
        :closable="false"
        v-model="showdrawer"
        title="添加类型名称"
        width="700">
      <Form ref="formValidate" :model="formDynamic" :rules="ruleformDynamic" :label-width="80">
        <FormItem label="类型" prop="name" :label-width="80" style="width: 77%">
          <Input v-model="formDynamic.name" placeholder="请输入类型名称"></Input>
        </FormItem>
        <FormItem label="描述" prop="desc" :label-width="80" style="width: 77%">
          <Input v-model="formDynamic.desc" placeholder="请输入描述信息"></Input>
        </FormItem>
        <FormItem>
          <Button @click="addform('formValidate')" type="primary">确定</Button>&nbsp;
          <Button @click="addcancel">取消</Button>
        </FormItem>
      </Form>
    </Drawer>
  </Card>
</div>
</template>

<script>
import {get,post} from "@/api/http.js";
export default {
  name: "Customtype",
  data () {
    return {
      showdrawer: false,
      formDynamic: {},
      ruleformDynamic: {
        name: [
          { required: true, message: '名称不能为空'}
        ],
      },
      total: 0,
      pagesizeopts: [10, 30, 50, 100],
      queryinfo: {
        page: 1,
        pagesize: 10,
        name:"",
      },
      columns: [
        {type: 'selection', width: 60, align: 'center'},
        {title: '类型', key: 'name'},
        {title: '描述', key: 'desc'},
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showupdatedrawer(params.row)
                  }
                }
              }, '修 改 |'),
              h('a', {
                on: {
                  click: () => {
                    this.isdelete(params.row)
                  }
                }
              }, '删 除')
            ]);
          }
        }
      ],
      data: [],
    }
  },
  methods: {
    showadd() {
      this.formDynamic = {}
      this.showdrawer = true
    },
    namechange() {
      this.queryinfo.name = this.queryinfo.name.trim()
      this.getdata(this.queryinfo)
    },
    changepage (page) {
      this.queryinfo.page = page
      this.getdata(this.queryinfo)
    },
    changepagesize(pagesize) {
      this.queryinfo.pagesize = pagesize
      this.getdata(this.queryinfo)
    },
    addcancel () {
      this.showdrawer = false
    },

    addfunc(data){
      const url = "/goansible/api/v1/ansible/type"
      post(url,data).then((resp)=>{
        if (resp.code===0){
          this.$Message.success(resp.msg);
          this.showdrawer = false
          this.getdata(this.queryinfo)
        } else {
          this.$Message.error("请稍后再试");
        }
      }).cache((e)=>{
        this.$Message.error("请稍后再试");
      })
    },

    addform (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.addfunc(this.formDynamic)
        } else {
          this.$Message.error('请完善表弟信息');
        }
      })
    },

    getdata(queryinfo) {
      const url = "/goansible/api/v1/ansible/type"
      get(url,queryinfo).then((resp)=>{
        if (resp["code"] === 0){
          this.total = resp["count"]
          this.data = resp["data"]
        } else {
          this.$Message.error("请求失败，请稍后再试")
        }
      })
    },
  },
  created() {
    this.getdata(this.queryinfo)
  },
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
